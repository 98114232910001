import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import urlSlug from "url-slug";
import { Helmet } from "react-helmet";
import Container from "../../Components/Container";
import FlexInColumn from "../../Components/FlexInColumn";
import FlexInRow from "../../Components/FlexInRow";
import PageTitle from "../../Components/PageTitle";
import Filter from "../../Components/Filter";
import Pagination from "../../Components/Pagination";
import RecommendedLinks from "../../Components/RecommendedLinks";
import axios, { all } from "axios";

const Wrapper = styled.main`
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  margin-top: 4.688vw;

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  img {
    width: 100%;

    height: 100%;
  }

  .icon {
    margin-right: 8px;
  }

  > .content {
    padding: 0 0 5.208vw;
  }

  @media (max-width: 992px) {
    margin-top: 120px;
  }
`;

const SectionCards = styled.div`
  display: grid;
  gap: 2.604vw;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  margin-bottom: 30px;

  .evento-excerpt {
    height: min-content !important;
    margin-bottom: 5px;
  }

  .evento-excerpt p,
  .evento-excerpt h1 {
    font-size: 16px !important;
    color: #000;
  }

  .item {
    border: 0.156vw solid #494d7d;
    border-radius: 1.042vw;
    justify-content: space-between;
    overflow: hidden;
    display: grid;
    grid-template-columns: 2fr 1fr !important;
  }

  .item__content {
    padding: 1.042vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // .item__image {
  //   border: none;
  //   overflow: hidden;
  //   width: 13.542vw;

  //   img {
  //     height: 100%;
  //     object-fit: cover;
  //     object-position: top;
  //   }
  // }

  .item__title {
    text-align: left;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 10px 0;
  }

  .item__text {
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.729vw;
  }

  .item__cta {
    align-items: center;
    justify-content: center;
    display: flex;
    background: #0c1151 0% 0% no-repeat padding-box;
    border-radius: 0.521vw;
    opacity: 1;
    text-align: left;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    width: 8.021vw;
    height: 2.396vw;
    transition: 0.3s ease;
  }

  .item__cta:hover {
    background-color: #8688a8;
    color: #e5e5e5;
  }

  .item__date {
    text-align: left;
    flex-wrap: wrap;
    font-size: 0.938vw;
    line-height: 1.25vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.521vw;
  }
  .tag-evento-spap {
    background: rgb(12, 17, 81);
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
  }
  .tag-evento-nispap {
    background: #000;
    color: #fff;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
  }

  .tag-evento-pago {
    background: rgb(248, 132, 54);
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
  }
  .tag-evento-gratuito {
    background: rgb(12, 17, 81);
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
  }
  .tag-evento-adquirido {
    background: #59b46e;
    color: #fff;
    font-size: 11px;
    padding: 2px 10px;
    border-radius: 10px;
  }
  .item__date svg {
    font-size: 1.302vw;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 20px;

    .item__title {
      font-size: 4.333vw;
      line-height: 5.444vw;
    }

    .item__text,
    .item__cta,
    .item__date,
    .item__date svg,
    p {
      font-size: 3.333vw;
      line-height: 4.444vw;
    }

    .item__cta {
      border-radius: 8px;
      font-size: 12px;
      line-height: 16px;
      width: 155px;
      padding: 15px 20px;
    }
  }

  @media (min-width: 1920px) {
    .item__text,
    .item__cta {
      font-size: 18px;
      line-height: 24px;
    }

    .item__date,
    .item__date svg {
      font-size: 22px;
      line-height: 29px;
    }

    .item__title {
      font-size: 24px;
      line-height: 28px;
      margin: 10px 0;
    }

    .item__cta {
      border-radius: 10px;
      padding: 15px 30px;
      width: 220px;
    }
  }
`;

const FilterForPosts = styled.form`
  background-color: #d6cdf7;
  border-radius: 1.042vw;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.484vw;
  position: relative;
  width: 100%;
  z-index: 5;
  place-self: flex-start;

  .title {
    background-color: #0c1151;
    padding: 1.563vw;
    text-align: center;
    font-size: 2.083vw;
    font-weight: bold;
    line-height: 2.5vw;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2vw;
    padding: 2.604vw;
    font-size: 18px;
    line-height: 24px;

    #name {
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: hsl(0, 0%, 100%);
      border-color: hsl(0, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      box-sizing: border-box;

      padding: 0 10px;
    }

    .content__select {
      text-align: left;
      color: #222222;
    }
  }

  #select-4 {
  }

  #submitFilter {
    max-width: 11.406vw;
    margin: auto auto -2.969vw;
    background-color: #f88436;
    border: none;
    border-radius: 0.521vw;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    transition: 0.3s ease;
    padding: 0.781vw 1.563vw;
  }

  #submitFilter:hover {
    background-color: #e87426;
    color: #ececec;
  }

  @media (max-width: 992px) {
    width: 100%;
    font-size: 2.5vw;
    grid-column-start: 1;
    grid-row-start: 1;
    margin-bottom: 40px;

    .content {
      grid-template-columns: 1fr;
      font-size: 14px;
      line-height: 18px;
    }

    .title {
      font-size: 3.444vw;
      line-height: 4.555vw;
    }

    #submitFilter {
      font-size: 3.333vw;
      line-height: 4.444vw;
      padding: 10px 30px;
      border-radius: 8px;
      max-width: 220px;
      margin: auto auto -5.8vw;
    }
  }

  @media (min-width: 1920px) {
    .title {
      font-size: 40px;
      line-height: 48px;
      padding: 30px;
    }

    .content {
      gap: 30px 33px;
      padding: 40px 52px;
      font-size: 24px;
      line-height: 28px;
    }

    #submitFilter {
      font-size: 22px;
      line-height: 27px;
      max-width: 169px;
    }
  }
`;

const NoResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    text-align: center;
    font-size: 16px;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0c1151;
    opacity: 1;
    margin: 1.094vw 0;
  }
`;

const SectionMembers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const SectionMembersContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1;

  a {
    grid-area: cta;
    text-align: left;
    font-size: 0.938vw;
    font-weight: bold;
    line-height: 1.25vw;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: ${(props) => props.theme.blue};
    border-radius: 0.417vw;
    opacity: 1;
    transition: 0.3s ease;
    margin: 0;
    place-self: flex-start;
    padding: 0.521vw 1.563vw;
    text-decoration: none;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: ${(props) => props.theme.blueHover};
      color: #e5e5e5;
    }
  }

  @media (max-width: 992px) {
    a {
      border-radius: 8px;
      font-size: 18px;
      line-height: 24px;
      padding: 10px 20px;
    }
  }
`;

const SectionMembersImage = styled.div`
  overflow: hidden;
  margin: 40px 0;

  @media (max-width: 992px) {
    margin: 20px 0;
    width: 60%;
  }
`;

const SectionMembersTitle = styled.h3`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: inherit;
  letter-spacing: 0px;
  color: ${(props) => props.theme.blue};
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const animatedComponents = makeAnimated();

export default function Agenda(props) {
  const [noResults, setNoResults] = useState("");
  const session_storage = JSON.parse(localStorage.getItem("session_data"));

  const [listEventosToShow, setListEventosToShow] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [eventos, setEventos] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedLocal, setSelectedLocal] = useState("");
  const [selectedYear, setSelectedYear] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [optionsType, setOptionsType] = useState([]);
  const [optionsYear, setOptionsYear] = useState([]);
  const [optionsLocal, setOptionsLocal] = useState([]);

  const optionsMonth = [
    { value: "", label: "Todos os meses" },
    { value: "01", label: "Janeiro" },
    { value: "02", label: "Fevereiro" },
    { value: "03", label: "Março" },
    { value: "04", label: "Abril" },
    { value: "05", label: "Maio" },
    { value: "06", label: "Junho" },
    { value: "07", label: "Julho" },
    { value: "08", label: "Agosto" },
    { value: "09", label: "Setembro" },
    { value: "10", label: "Outubro" },
    { value: "11", label: "Novembro" },
    { value: "12", label: "Dezembro" },
  ];

  const optionsArea = [
    { value: "Patologia geral", label: "Patologia geral" },
    { value: "Citologia", label: "Citologia" },
    { value: "Patologia cutânea", label: "Patologia cutânea" },
    {
      value: "Patologia da cabeça e pescoço",
      label: "Patologia da cabeça e pescoço",
    },
    {
      value: "Patologia das partes moles",
      label: "Patologia das partes moles",
    },
    {
      value: "Patologia do sistema nervosa",
      label: "Patologia do sistema nervoso",
    },
    { value: "Patologia digital", label: "Patologia digital" },
    { value: "Patologia endócrina", label: "Patologia endócrina" },
    { value: "Patologia fetoplacentar", label: "Patologia fetoplacentar" },
    {
      value: "Patologia gastrointestinal",
      label: "Patologia gastrointestinal",
    },
    { value: "Patologia ginecológica", label: "Patologia ginecológica" },
    { value: "Patologia hematolinfoide", label: "Patologia hematolinfoide" },
    { value: "Patologia hepatobiliar", label: "Patologia hepatobiliar" },
    { value: "Patologia mamária", label: "Patologia mamária" },
    { value: "Patologia molecular", label: "Patologia molecular" },
    { value: "Patologia osteoarticular", label: "Patologia osteoarticular" },
    { value: "Patologia pancreática", label: "Patologia pancreática" },
    { value: "Patologia pulmonar", label: "Patologia pulmonar" },
    { value: "Patologia urogenital", label: "Patologia urogenital" },
  ];

  const month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const removeHTMLTags = (htmlString) => {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string into a DOM document
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract the text content from the parsed document
    const textContent = doc.body.textContent || "";
    return textContent.trim(); // Trim any leading or trailing whitespace
  };

  const checkIfReachedMaxTickets = (count_tickets, tickets) => {
    let qtt = tickets.reduce((n, { qtd }) => n + qtd, 0);
    console.log(qtt);
    console.log(count_tickets);
    if (count_tickets >= qtt) {
      return false;
    }

    return true;
  };
  const getValueTickets = (tickets) => {
    if (tickets.some((e) => e.cargo == "todos")) {
      let valueTicket = tickets.filter((ticket) => {
        return ticket.cargo == "todos";
      });
      return valueTicket[0].preco;
    }
    if (!session_storage && tickets.some((e) => e.cargo == "nao_socio")) {
      let valueTicket = tickets.filter((ticket) => {
        return ticket.cargo == "nao_socio";
      });
      return valueTicket[0].preco;
    }
    if (
      (session_storage &&
        tickets.some(
          (e) =>
            e.cargo ==
            session_storage.all_data.user_session?.tipo_socio?.toLowerCase()
        )) ||
      tickets.some(
        (e) =>
          e.cargo ==
          session_storage.all_data.user_session?.tipo_utilizador?.toLowerCase()
      )
    ) {
      let valueTicket = tickets.filter((ticket) => {
        return (
          ticket.cargo ==
            session_storage.all_data.user_session?.tipo_socio?.toLowerCase() ||
          ticket.cargo ==
            session_storage.all_data.user_session?.tipo_utilizador?.toLowerCase()
        );
      });
      return valueTicket[0].preco;
    }
    if (
      session_storage &&
      tickets.some(
        (e) =>
          e.cargo == session_storage.all_data.user_session?.grau?.toLowerCase()
      )
    ) {
      let valueTicket = tickets.filter((ticket) => {
        return (
          ticket.cargo ==
          session_storage.all_data.user_session?.grau?.toLowerCase()
        );
      });
      return valueTicket[0].preco;
    }
  };

  const getEventos = (allEventos, purchases, paymentDetails) => {
    let eventosToShow = [];

    let today = new Date().toLocaleDateString();

    let eventos = allEventos.filter((evento) => {
      if (evento.is_event == 1) return evento;
    });

    let opcoesAno = [];
    opcoesAno.push({ value: "20", label: "Todos os anos" });

    let opcoesLocal = [];
    opcoesLocal.push({ value: "", label: "Todos" });

    if (eventos?.length > 0) {
      eventos?.map((evento) => {
        evento.slug = urlSlug(evento.titulo);

        evento.slug += "-" + evento.id;

        let mostraEvento = true;

        if (evento.agendar_post != null) {
          let date = evento.agendar_post.trim();

          if (date > today) {
            mostraEvento = false;
          }
        }

        if (mostraEvento) {
          evento.purchased = false;
          evento.soldOut = false;
          evento.isSpap = false;
          if (evento.data_inicio) {
            const [dateInicioValues, timeInicioValues] =
              evento.data_inicio.split(" ");
            const [dayInicio, monthInicio, yearInicio] =
              dateInicioValues.split("/");
            const [dateFechoValues, timeFechoValues] =
              evento.data_fecho.split(" ");
            const [dayFecho, monthFecho, yearFecho] =
              dateFechoValues.split("/");

            opcoesAno.push({ value: yearInicio, label: yearInicio });

            evento.dataAOrdenar =
              monthInicio + "/" + dayInicio + "/" + yearInicio;

            if (evento.localizacao != "" && evento.localizacao != null) {
              opcoesLocal.push({
                value: evento.localizacao,
                label: evento.localizacao,
              });
            }

            if (evento.is_spap != 0) {
              evento.isSpap = true;
            }

            const monthInicioToString = month[Number(monthInicio - 1)];
            const monthFechoToString = month[Number(monthFecho - 1)];

            evento.monthInicio = monthInicio;
            evento.yearInicio = yearInicio;

            evento.url = evento.imagem_destaque
              ? "https://api.spap.pt/eventos/" +
                evento.id +
                "/" +
                evento.imagem_destaque
              : "https://spap.pt/clubes/clube-estudo-default.svg";

            if (
              monthInicio == monthFecho &&
              yearInicio === yearFecho &&
              dayInicio === dayFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio == monthFecho && yearInicio === yearFecho) {
              evento.date =
                dayInicio +
                "-" +
                dayFecho +
                " " +
                monthInicioToString +
                " " +
                yearInicio;
            } else if (
              dayInicio == dayFecho &&
              monthInicio == monthFecho &&
              yearInicio === yearFecho
            ) {
              evento.date =
                dayInicio + " " + monthInicioToString + " " + yearInicio;
            } else if (monthInicio != monthFecho) {
              evento.date =
                dayInicio +
                " " +
                monthInicioToString +
                " a " +
                dayFecho +
                " " +
                monthFechoToString +
                " " +
                yearFecho;
            }

            if (purchases.includes(evento.id)) {
              evento.purchased = true;
            }

            let details = paymentDetails.filter((detail) => {
              return detail.event_id == evento.id;
            });
            evento.ticketValue = 0;
            if (details.length > 0) {
              if (
                !checkIfReachedMaxTickets(
                  details[0].count_tickets,
                  JSON.parse(details[0].tickets)
                )
              ) {
                evento.soldOut = true;
              }
              console.log(details[0].tickets);
              evento.ticketValue = getValueTickets(
                JSON.parse(details[0].tickets)
              );
            }

            eventosToShow.push(evento);
          }
        }
      });
    }

    const uniqueArrayAnos = opcoesAno.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        opcoesAno.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    setOptionsYear(uniqueArrayAnos);

    const uniqueArrayLocal = opcoesLocal.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        opcoesLocal.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    setOptionsLocal(uniqueArrayLocal);

    const eventosOrdenados = eventosToShow.sort(function (a, b) {
      const dataA = new Date(a.dataAOrdenar);
      const dataB = new Date(b.dataAOrdenar);
      const hoje = new Date();

      return dataB - dataA;
    });

    setEventos(eventosOrdenados);
  };

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-eventos`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar eventos");
        }
        return response.json();
      })
      .then((data) => {
        if (data.eventos.length === 0) {
          setNoResults("Nenhum evento encontrado..");
        }
        if (session_storage) {
          session_storage.all_data.user_session.tipo_socio =
            data.user.tipo_socio;
          session_storage.all_data.user_session.grau = data.user.grau;
        }
        getEventos(data.eventos, data.purchases, data.eventPayments);
        getOptionsType(data.clubes);
        getOptionsYear(data.eventos);
        getOptionsLocal(data.eventos);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setAllItems(eventos);
    setListEventosToShow(eventos);
    setCurrentItems(listEventosToShow.slice(0, 8));
  }, [eventos]);

  const getOptionsType = (clubes) => {
    let clubesToShow = [];

    clubesToShow.push({ value: "", label: "SPAP Geral" });

    clubes?.map((clube) => {
      clubesToShow.push({ value: clube.id, label: clube.name });
    });

    setOptionsType(clubesToShow);
  };

  const getOptionsYear = (eventos) => {
    let yearsToShow = [];

    yearsToShow.push({ value: "", label: "Todos os anos" });

    eventos?.map((evento) => {
      if (evento.data_inicio) {
        const [dateInicioValues, timeInicioValues] =
          evento.data_inicio.split(" ");
        const [dayInicio, monthInicio, yearInicio] =
          dateInicioValues.split("/");
        yearsToShow.push({ value: yearInicio, label: yearInicio });
      }
    });

    const uniqueArray = yearsToShow.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        yearsToShow.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    uniqueArray.sort((a, b) => {
      if (a.value === "") {
        return -1; // Move o value vazio para o início
      } else if (b.value === "") {
        return 1; // Move o value vazio para o início
      }
      return b.value - a.value; // Ordena decrescente por anos
    });
    setOptionsYear(uniqueArray);
  };

  const getOptionsLocal = (eventos) => {
    let localizacoesToShow = [];

    localizacoesToShow.push({ value: "", label: "Todas as localizações" });

    eventos?.map((evento) => {
      if (evento.localizacao != null) {
        localizacoesToShow.push({
          value: evento.localizacao,
          label: evento.localizacao,
        });
      }
    });

    const uniqueArray = localizacoesToShow.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        localizacoesToShow.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });

    setOptionsLocal(uniqueArray);
  };

  const handleSearch = (e) => {
    let year = selectedYear;
    let area = selectedArea;
    let local = selectedLocal;
    let tipo = selectedType;

    if (
      year.length == 0 &&
      month.length == 0 &&
      local.length == 0 &&
      tipo.length == 0
    ) {
      setListEventosToShow(allItems);
      setCurrentItems(listEventosToShow.slice(0, 8));
      return;
    }

    let novosEventosAListar = eventos.filter((evento) => {
      let showEvento = true;

      if (year.length > 0) {
        if (!evento.yearInicio.includes(year)) {
          showEvento = false;
        }
      }

      if (local.length > 0) {
        if (!local.includes(evento.localizacao)) {
          showEvento = false;
        }
      }

      if (area.length > 0) {
        if (!area.includes(evento.area_subespecializacao)) {
          showEvento = false;
        }
      }

      if (tipo != "") {
        if (evento.club_id != tipo) {
          showEvento = false;
        }
      }

      if (showEvento) return evento;
    });

    setListEventosToShow(novosEventosAListar);
    setCurrentItems(novosEventosAListar.slice(0, 8));
    if (novosEventosAListar.length <= 0) {
      setNoResults("Nenhum evento encontrado..");
    } else {
      setNoResults("");
    }
  };

  useEffect(() => {
    setCurrentItems(listEventosToShow.slice(0, 8));
  }, [listEventosToShow]);

  return (
    <Wrapper>
      <Helmet>
        <title>
          Agenda | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Fique a par de todas os eventos da Sociedade Portuguesa de Anatomia Patológica - SPAP"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="AGENDA"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Agenda",
            },
          ]}
          imageDesktop={require("../../Assets/Images/Agenda@2x.png")}
          imageMobile={require("../../Assets/Images/Agenda.png")}
          imageAlt="Agenda"
        />
        <FlexInRow gap="40px">
          {currentItems.length > 0 ? (
            <>
              <SectionCards>
                {currentItems.slice(0, 8)?.map((item, index) => {
                  let titlePreview = item.titulo.slice(0, 60);
                  if (item.titulo.length > 60) titlePreview += " (...)";
                  let textPreview = removeHTMLTags(item.descricao).slice(
                    0,
                    120
                  );
                  if (item.descricao.length > 120) textPreview += " (...)";
                  return (
                    <div className={"item item-" + index}>
                      <div className="item__content">
                        <div className="mb-10">
                          <div className="item__date">
                            <FontAwesomeIcon icon={faCalendarDay} />
                            {item.date}
                            {item.purchased == true && (
                              <>
                                <span className="tag-evento-adquirido hide-event">
                                  ESTE EVENTO JÁ FOI ADQUIRIDO
                                </span>
                              </>
                            )}
                            {item.purchased == false &&
                              item.soldOut == true && (
                                <>
                                  <span className="tag-evento-pago hide-event">
                                    ESTE EVENTO ENCONTRA-SE ESGOTADO
                                  </span>
                                </>
                              )}
                            {item.is_pago == true &&
                              item.ticketValue != 0 &&
                              item.purchased == false && (
                                <>
                                  <span className="tag-evento-pago hide-event">
                                    EVENTO PAGO
                                  </span>
                                </>
                              )}
                            {item.is_pago == true &&
                              item.ticketValue == 0 &&
                              item.purchased == false && (
                                <>
                                  <span className="tag-evento-gratuito">
                                    EVENTO GRATUITO - INSCRIÇÃO OBRIGATÓRIA
                                  </span>
                                </>
                              )}
                          </div>
                          <h3 className="item__title">{titlePreview}</h3>
                          <div
                            className="evento-excerpt"
                            dangerouslySetInnerHTML={{ __html: textPreview }}
                          ></div>
                          {item.isSpap == false ? (
                            <>
                              <span className="tag-evento-nispap">NISPAP</span>
                            </>
                          ) : (
                            <>
                              <span className="tag-evento-spap">SPAP</span>
                            </>
                          )}
                        </div>
                        <div>
                          <Link
                            to={"/agenda/evento/" + item.slug}
                            className="item__cta"
                          >
                            {item.is_pago == 1 ? "Inscrever »" : "Saber mais »"}
                          </Link>
                        </div>
                      </div>
                      <div
                        className="item__image"
                        style={{
                          backgroundImage: "url(" + item.url + ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                      >
                        <picture style={{ opacity: "0" }}>
                          <source
                            srcSet={item.url}
                            media="(min-width: 990px)"
                          />
                          <img src={item.url} alt={item.alt} />
                        </picture>{" "}
                      </div>
                    </div>
                  );
                })}
              </SectionCards>
              <Pagination
                items={listEventosToShow}
                itemsPerPage={8}
                onchange={(e) => {
                  setCurrentItems(e);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              />

              <SectionCards>
                <FilterForPosts
                  onSubmit={(e) => {
                    e.preventDefault();

                    handleSearch();
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <h2 className="title">ENCONTRE AQUI TODOS OS EVENTOS</h2>
                  <div className="content">
                    {/* <Select
                  id="select-1"
                  className="content__select"
                  placeholder="Tipologia"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={optionsType}
                  onChange={(e) => {setSelectedType(e.value)}}
                /> */}
                    <Select
                      id="select-2"
                      className="content__select"
                      placeholder="Local do evento"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={optionsLocal}
                      isClearable={true}
                      onChange={(e) => setSelectedLocal(e ? e.value : "")}
                    />
                    <Select
                      id="select-3"
                      className="content__select"
                      placeholder="Ano do evento"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={optionsYear}
                      isClearable={true}
                      onChange={(e) => setSelectedYear(e ? e.value : "")}
                    />
                    <Select
                      id="select-4"
                      className="content__select"
                      placeholder="Área de Diferenciação"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={optionsArea}
                      isClearable={true}
                      onChange={(e) => setSelectedArea(e ? e.value : "")}
                    />
                  </div>
                  <button id="submitFilter" type="submit">
                    Pesquisar
                  </button>
                </FilterForPosts>
                <SectionMembers>
                  <SectionMembersContent>
                    <SectionMembersTitle>
                      Quer divulgar um evento?
                    </SectionMembersTitle>
                    <p>
                      <br />
                      Contacte-nos!
                    </p>
                    <SectionMembersImage>
                      <picture>
                        <source
                          srcSet={require("../../Assets/Images/Eventos/Pretende-divulgar-um-evento-SPAP@2x.png")}
                          media="(min-width: 990px)"
                        />
                        <img
                          src={require("../../Assets/Images/Eventos/Pretende-divulgar-um-evento-SPAP.png")}
                          alt="Seja um Membro"
                        />
                      </picture>
                    </SectionMembersImage>
                    <Link to="/spap/contactos">Preencher formulário »</Link>
                  </SectionMembersContent>

                  <SectionMembersContent>
                    <SectionMembersTitle>
                      Quer divulgar um trabalho que apresentou num evento?
                    </SectionMembersTitle>
                    <p>Envie-nos os dados.</p>
                    <SectionMembersImage>
                      <picture>
                        <source
                          srcSet={require("../../Assets/Images/Eventos/Quer-partilhar-um-trabalho-SPAP@2x.png")}
                          media="(min-width: 990px)"
                        />
                        <img
                          src={require("../../Assets/Images/Eventos/Quer-partilhar-um-trabalho-SPAP.png")}
                          alt="Seja um Membro"
                        />
                      </picture>
                    </SectionMembersImage>
                    <Link to="/spap/contactos">Preencher formulário »</Link>
                  </SectionMembersContent>
                </SectionMembers>
              </SectionCards>
            </>
          ) : (
            <NoResults>
              <h3>{noResults}</h3>
            </NoResults>
          )}
        </FlexInRow>
      </Container>

      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "Lista de sócios",
            link: "/socios/lista-de-socios",
          },
          {
            title: "Pedir uma 2ª opinião",
            link: "",
          },
          {
            title: "Área pessoal",
            link: "/socios/area-de-socios",
          },
          {
            title: "Contactos",
            link: "/spap/contactos",
          },
        ]}
      />
    </Wrapper>
  );
}
