import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import Container from '../../../Components/Container'
import AuthService from "../../../Services/auth.service"
import { useForm } from "react-hook-form"
import { CheckCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';


import { ReactComponent as LogoSpap } from '../../../Assets/Images/Backoffice/Login/SPAP-Sociedade-Portuguesa-de-Anatomia-Patologica.svg'

const Wrapper = styled.main`
  background-image: url(${require('../../../Assets/Images/Backoffice/Login/Background-Login@2x.png')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 4.167vw 0 1.302vw;

  min-height: calc(100vh - 3.12vw);

  @media (max-width: 992px) {
    background-image: url(${require('../../../Assets/Images/Backoffice/Login/Background-Login.png')});
  }
`

const Header = styled.header`
  align-items: center;  
  display: flex;
  justify-content: flex-start;

  .logo {
    width: 20.729vw;
  }
`

const Footer = styled.footer`
  background-color: ${props => props.theme.blueLight};
  padding: 1.563vw 0;
`

const Form = styled.div`
  background-color: #FFFFFF;
  border-radius: 0.833vw;
  margin: 0 auto;
  max-width: 536px;
  padding: 2.083vw 4.271vw;
`

const FormTitle = styled.h1`
  color: ${props => props.theme.blue};
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0px;
`

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    color: #809FB8;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }

  p.left {
    text-align: left;
  }

  p>* {
    color: #809FB8;
  }

  label {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0C1151;
  }

  input[type="email"],
  input[type="password"] {
    background-color: #D9E1E700;
    border: 2px solid #D9E1E7;
    border-radius: 0.625vw;
    padding: 0.729vw;
    width: 100%;
    outline: 0;
    transition: .3s ease;
    margin-top: 0.260vw;
    text-align: center;
  }

  input[type="password"] {
    margin-bottom: 0px;
  }

  input[type="email"]:focus,
  input[type="password"]:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  input[type="checkbox"] {
    border: 2px solid #E0E7EB;
    border-radius: 0.417vw;
    cursor: pointer;
    height: 1.302vw;
    margin-right: 11px;
    width: 1.302vw;
  }

  input[type="submit"] {
    background-color: ${props => props.theme.blueLight};
    border: none;
    border-radius: 12px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0px;
    color: ${props => props.theme.white};
    opacity: 1;
    padding: 15px 30px;
    place-self: center;
    transition: .3s ease;
    margin-top: 10px;
  }

  input[type="submit"]:hover {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.whiteHover};
  }

  .link-checkbox,
  .link-login {
    transition: .3s ease;
  }

  .link-login {
    color: rgb(73, 77, 125);
  }

  .link-login:hover {
    color: #809FB8;
  }
  
  .link-checkbox:hover {
    color: rgb(73, 77, 125);
  }

  @media (max-width: 992px) {
    input[type="text"],
    input[type="password"] {
      border-radius: 2.778vw;
      font-size: 3.889vw;
      line-height: 5.278vw;
      padding: 3.333vw 4.167vw;
    }

    input[type="checkbox"] {
      height: 15px;
      width: 15px;
    }

    input[type="submit"] {
      border-radius: 2.778vw;
      font-size: 3.611vw;
      line-height: 5vw;
      padding: 2.778vw 4.167vw;
      margin-top: 5.556vw;
    }
  }

    span[role=alert] {
    color:red;
  }
`

export default function RecuperarSenha ( props ) {
  document.title = 'SPAP - Recuperar Senha'

  const navigate = useNavigate();
  const [hasError,setHasError] = useState(false);
  const [hasSuccess,setHasSuccess] = useState(false);
  const [errorMessage,setErrorMessage] = useState(false);


  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get('token');

  const [mostraPass,setMostraPass] = useState(function() {
    if((email == null) || (token == null)) {
      return false;
    } else {
      return true;
    }
  });  


  const handleSubmitRecuperar = (e) => {

    e.preventDefault();

    let email_input = e.target.email.value;

    AuthService.recuperarPassword(
      email_input
    ).then((response) => {
      setHasSuccess(true);
    }).catch((e) =>  {
      setHasError(true);
      setErrorMessage("O e-mail introduzido não se encontra registado.");
      setTimeout(() => {
        setHasError(false);
      },3000)
      return false

    });
  };

  const handleSubmitAlterar = (e) => {

    e.preventDefault();

    let password = e.target.password.value;
    let password_confirm = e.target.password_confirm.value;

    if (password !== password_confirm) {
      setHasError(true);
      setErrorMessage("As passwords não coincidem.");
      return;
    }

    AuthService.alteraPassword(
      email, token, password
    ).then((response) => {
      setHasSuccess(true);
      
      setTimeout(() => {
        setHasSuccess(false);
        navigate("/login");  
      },4000)
    }).catch((e) => {
      setHasError(true);
      setErrorMessage("A sua senha não foi atualizada. Introduza o seu e-mail novamente.");
      setTimeout(() => {
        setHasError(false);
      },3000)
      setMostraPass(false);
    });
  };

  return(
    <>
      <Wrapper>
        <Container>
          <Header>
            <LogoSpap className='logo'/>
          </Header>
          <Form>
            <FormTitle>Recuperar sua password</FormTitle>
            {
              // verificar se exibe campo email ou passwords
              // (!location.search)
              (mostraPass == false)
              ?

              <FormContent onSubmit={(e) => handleSubmitRecuperar(e)}>
                <p>Coloque abaixo o seu email</p>
                <label>
                  Email *
                  <input type="email" name="email" placeholder="Email" required />
                </label>
                {hasError && 
                  <div style={{ padding: '10px', background: '#ffc3c3', border: '1px solid #ffc3c3', borderRadius: '10px', display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined twoToneColor="red"/> <span style={{ paddingLeft: '10px', fontSize: '14px'}}>{errorMessage}</span></div>
                }
                {hasSuccess && 
                  <div style={{ padding: '10px', background: '#a1f9ab', border: '1px solid #a1f9ab', borderRadius: '10px', display: 'flex', alignItems: 'center'}}><CheckCircleTwoTone twoToneColor="green"/> <span style={{ paddingLeft: '10px', fontSize: '14px'}}>Foi enviado um e-mail para poder recuperar a sua senha.</span></div>
                }
                <input type="submit" name="submit" value="Recuperar" className='w-100'/>
                {/* <p>Ainda não tenho conta. <a href="/register"><u>Registe-se aqui</u></a></p> */}
                <p style={{ marginTop: '10px'}}>Desejo voltar para o <Link to="/login" className='link-login'><u>Login</u></Link></p>
              </FormContent>

              :

              <FormContent onSubmit={(e) => handleSubmitAlterar(e)}>
                <p>Defina sua nova senha abaixo</p>
                <label>
                  Nova Password *
                  <input type="password" name="password" placeholder="Password" required />
                </label>
                <label>
                  Confirmar Nova Password *
                  <input type="password" name="password_confirm" placeholder="Password" required />
                </label>
                {hasError && 
                  <div style={{ padding: '10px', background: '#ffc3c3', border: '1px solid #ffc3c3', borderRadius: '10px', display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined twoToneColor="red"/> <span style={{ paddingLeft: '10px', fontSize: '14px'}}>{errorMessage}</span></div>
                }
                {hasSuccess && 
                  <div style={{ padding: '10px', background: '#a1f9ab', border: '1px solid #a1f9ab', borderRadius: '10px', display: 'flex', alignItems: 'center'}}><CheckCircleTwoTone twoToneColor="green"/> <span style={{ paddingLeft: '10px', fontSize: '14px'}}>A sua senha foi atualizada com sucesso. Será redirecionado para a página de login.</span></div>
                }
                <input type="submit" name="submit" value="Recuperar"/>
              </FormContent>
            }
          </Form> 
        </Container>
      </Wrapper>
      <Footer>
        <Container>

        </Container>
      </Footer>
    </>
  )
}