import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import {
  Button,
  Row,
  Col,
  Drawer,
  Input,
  Space,
  DatePicker,
  Divider,
  Upload,
  Table,
  notification,
  Modal,
} from "antd";
import {
  CloseCircleOutlined,
  SearchOutlined,
  LinkOutlined,
  UploadOutlined,
  EditOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ExportButton from "../ExportButton";
import Highlighter from "react-highlight-words";
const { RangePicker } = DatePicker;

const VoucherCard = styled.div`
  margin: 15px;
  background: #e8e9f3;
  border-radius: 15px;
  padding: 30px;

  .voucher-btn,
  .ant-btn {
    border: 1px solid #494d7d !important;
    color: #494d7d !important;
    height: 45px !important;
    background: transparent !important;
    width: 100% !important;
    display: flex;
    align-iems: center;
    justify-content: center;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .inputDados .ant-select-selection-item {
    color: #494d7d !important;
  }

  .inputDados.ant-select-disabled,
  .inputDados.ant-select-outlined.ant-select-disabled:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #fff !important;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .input-voucher,
  .input-voucher .ant-input,
  .input-voucher ::placeholder {
    background: #494d7d !important;
    border: 1px solid #494d7d !important;
    color: #fff !important;
  }

  ::placeholder {
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .inputDados {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 45px !important;
    line-height: 45px;
    width: 100%;
  }

  .inputDados .ant-select-selector {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    min-height: 45px !important;
    width: 100%;
    height: auto !important;
  }

  .total {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .ant-input-suffix {
    background: #494d7d !important;
    color: #fff !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .rangePicker {
    height: 45px;
    border: 1px solid #dfdfe4 !important;
    width: 100%;
  }

  .ant-input-disabled,
  .ant-select-disabled,
  .ant-picker-disabled {
    background: #fff !important;
  }

  .ant-select {
    min-height: 45px !important;
    height: auto !important;
  }

  .ant-picker-input input {
    color: #494d7d !important;
  }

  .voucher-manage {
    padding: 0 !important;
    background: #fff !important;
  }
`;

const ManageVoucher = styled.div`
  .voucher-btn,
  .ant-btn {
    border: 1px solid #494d7d !important;
    color: #494d7d !important;
    height: 45px !important;
    background: transparent !important;
    width: 100% !important;
    display: flex;
    align-iems: center;
    justify-content: center;
  }

  .upload-file-div {
    margin-top: 0px;
  }

  .text-right {
    text-align: right;
  }

  .anexar-title {
    font-size: 14px;
    color: #494d7d;
    margin-bottom: 0;
  }

  .anexar-subtitle {
    font-size: 10px;
    color: #809fb8;
    margin-bottom: 0;
  }

  .ant-upload-select {
    width: 100%;
  }

  .btn-upload
    .ant-upload-list-item-actions
    .ant-upload-list-item-action.ant-btn {
    border: none !important;
  }

  * {
    color: #494d7d;
  }

  .label-pago {
    background: #ddffdd;
    border-radius: 6px;
    padding: 4px 10px;
  }
  .label-nao-pago {
    background: #ffdddd;
    border-radius: 6px;
    padding: 4px 10px;
  }

  h5 {
    font-size: 20px !important;
    margin-bottom: 0;
  }

  .apply-code-btn {
    border-radius: 12px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .input-voucher,
  .input-voucher .ant-input,
  .input-voucher.ant-input-affix-wrapper,
  .input-voucher input::placeholder {
    background: #494d7d !important;
    border: 1px solid #494d7d !important;
    color: #fff !important;
  }

  ::placeholder {
    color: #fff !important;
    opacity: 1 !important; /* Firefox */
  }

  .apply-code-btn,
  .inputCode {
    border: 1px solid #494d7d !important;
    color: #494d7d;
    height: 35px;
  }

  .inputDados .ant-select {
    height: 45px;
  }
  .inputDados:not(.ant-select) {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    height: 45px !important;
    width: 100%;
  }

  .inputDados .ant-select-selector {
    border: 1px solid #dfdfe4 !important;
    color: #494d7d;
    min-height: 45px !important;
    width: 100%;
    height: auto !important;
  }

  .inputDados .ant-select-selection-placeholder {
    line-height: 35px !important;
    height: 45px !important;
  }

  .total {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  h6 {
    font-size: 16px;
  }

  .subtitle {
    font-size: 17px;
  }

  .view-btn {
    color: #fff;
    background: #494d7d;
    padding: 10px 30px;
    border-radius: 5px;
    text-decoration: none !important;
    margin-top: 20px;
  }

  .view-more {
    text-decoration: underline;
  }

  .delete-icon {
    font-size: 15px;
  }

  .margin-vertical-auto {
    margin: auto 0;
  }

  .next-btn,
  .next-btn span {
    background: #494d7d !important;
    color: #ffffff !important;
  }

  .pre-btn,
  .pre-btn span {
    background: #ffffff !important;
    color: #494d7d !important;
  }

  .ant-input-suffix {
    background: #494d7d !important;
    color: #fff !important;
  }

  .next-btn,
  pre-btn {
    height: auto !important;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .rangePicker {
    height: 45px;
    border: 1px solid #dfdfe4 !important;
    width: 100%;
  }

  .voucher-manage {
    padding: 0 !important;
    background: #fff !important;
  }

  .inputDados .ant-input-number-input-wrap input {
    height: 45px !important;
  }
`;

export default function DrawerEventPayments(props) {
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const [fileEventPayment, setFileEventPayment] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSaveFile = (data, record, eventId) => {
    const formData = new FormData();
    if (data.fileList.length > 0) {
      formData.append("file", data.fileList.length > 0 ? data.file : "");
    }
    formData.append("id", record.id);
    formData.append("eventId", eventId);
    formData.append("email", record.billing_email);
    formData.append("name", record.billing_name);

    notification.info({
      description:
        "Estamos a preparar o envio de email ao utilizador com o respetivo ficheiro",
    });

    fetch("https://api.spap.pt/api/admin/store-event-payment-file", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response
        .json()
        .then((data) => {
          notification.success({
            description: "O documento foi carregado com sucesso.",
          });
          handleEventPayments(data.purchases);
          onUpdate(data.purchases);
        })
        .catch((err) => {});
    });
  };

  const propsFilePayment = {
    onRemove: (file) => {
      const index = fileEventPayment.indexOf(file);
      const newFileList = fileEventPayment.slice();
      newFileList.splice(index, 1);
      setFileEventPayment(newFileList);
      // handleSaveFile(newFileList);
    },
    beforeUpload: (file) => {
      const isLt10M = file.size / 1024 / 1024 <= 10;
      if (!isLt10M) {
        notification.error({
          description: "Apenas é possível fazer upload de ficheiros até 10MB",
        });

        return false || Upload.LIST_IGNORE;
      }
      setFileEventPayment([file]);
      // handleSaveFile(file);
      return false;
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  useEffect(() => {
    setOpenDrawer(props.show);
    setDataToShow([]);
    if (props.show) {
      if (props.purchases?.length == 0) {
        setIsEmpty(true);
        return;
      }
      handleEventPayments(props.purchases);
    }
  }, [props]);

  const handleEventPayments = (allPurchases) => {
    let myPurchases = allPurchases?.filter((payment) => {
      if (payment.event_id == props?.eventId) {
        return payment;
      }
    });
    if (myPurchases.length == 0) {
      setIsEmpty(true);
      setDataToShow([]);
      return;
    }

    myPurchases.map((purchase) => {
      purchase.estado =
        purchase.status != "nao pago" ? (
          <>
            <span className="label-pago">Pago</span>
          </>
        ) : (
          <>
            <span className="label-nao-pago">Não pago</span>
          </>
        );
      purchase.montante = purchase.amount;
      if (Number(purchase.montante)) {
        purchase.montante = purchase.amount + " €";
      }
      purchase.fileList = purchase.invoice_file?.length
        ? [
            {
              uid: "-1",
              name: purchase.invoice_file,
              status: "done",
              url:
                "https://api.spap.pt/eventos/" +
                props?.eventId +
                "/purchases/" +
                purchase.invoice_file,
            },
          ]
        : [];
    });

    setDataToShow(myPurchases);
  };

  // useEffect(() => {
  //   if (props.events.length > 0) {
  //     let eventsToShow = [];
  //     props.events.map((event) => {
  //       eventsToShow.push({ value: event.id, label: event.titulo });
  //     });

  //     setOptionsEvents(eventsToShow);
  //   }
  // }, [props.events]);

  const handleSearchClear = () => {
    setValueSearch("");
  };

  const onCloseDrawer = () => {
    props.onChange(false);
  };

  const onUpdate = (purchases) => {
    props.onUpdate(purchases);
  };

  const mainColumns = [
    {
      title: "Nome",
      dataIndex: "billing_name",
      key: "nome",
      ...getColumnSearchProps("billing_name"),
    },
    {
      title: "UID",
      dataIndex: "user_id",
      key: "user_id",
      ...getColumnSearchProps("user_id"),
    },
    {
      title: "Email",
      dataIndex: "billing_email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "NIF",
      dataIndex: "billing_nif",
    },
    {
      title: "Cargo",
      dataIndex: "billing_cargo",
    },
    {
      title: "Método",
      dataIndex: "payment_type",
    },
    {
      title: "Montante",
      dataIndex: "montante",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      filters: [
        {
          text: "Pago",
          value: "pago",
        },
        {
          text: "Não pago",
          value: "nao pago",
        },
      ],
      filterSearch: true,
      onFilter: (value, record) => record.status.startsWith(value),
    },
    {
      title: "Data de pagamento",
      dataIndex: "payment_valid_date",
    },
  ];

  const mainColumnsToExport = [
    {
      title: "Nome",
      dataIndex: "billing_name",
    },
    {
      title: "Email",
      dataIndex: "billing_email",
    },
    {
      title: "NIF",
      dataIndex: "billing_nif",
    },
    {
      title: "Cargo",
      dataIndex: "billing_cargo",
    },
    {
      title: "Método",
      dataIndex: "payment_type",
    },
    {
      title: "Montante",
      dataIndex: "montante",
    },
    {
      title: "Estado",
      dataIndex: "status",
    },
    {
      title: "Data de pagamento",
      dataIndex: "payment_valid_date",
    },
  ];

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  return (
    <>
      <Drawer
        title={"Pagamentos do evento - " + props?.eventTitle}
        key={"eventUsers" + props?.eventId}
        width={1200}
        onClose={onCloseDrawer}
        open={openDrawer}
        extra={
          <Space>
            <Button
              onClick={() => {
                setOpenDrawer(false);
              }}
            >
              Fechar
            </Button>
            <ExportButton
              data={[...dataToShow]}
              columns={mainColumnsToExport}
              fileName={"socios-evento-" + props?.eventId}
            />
          </Space>
        }
      >
        <ManageVoucher>
          <Table
            columns={mainColumns}
            expandable={{
              expandedRowRender: (record) => (
                <Row
                  align={"middle"}
                  gutter={[8, 8]}
                  className="upload-file-div"
                >
                  <Col span={5}>
                    <p className="anexar-title">
                      <LinkOutlined /> Anexar Ficheiro
                    </p>
                    <p className="anexar-subtitle">
                      Anexe aqui documento PDF (máx. 10MB)
                    </p>
                  </Col>
                  <Col span={19}>
                    <Upload
                      key={"upload-pdf" + record.id}
                      {...propsFilePayment}
                      className="btn-upload"
                      accept={".pdf"}
                      fileList={record.fileList ?? []}
                      onChange={(file) =>
                        handleSaveFile(file, record, record.event_id)
                      }
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        Upload ficheiro (máx. 10MB)
                      </Button>
                    </Upload>
                    {/* if () */}
                    {/* <a
                        href={""}
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        Ver Ficheiro atual
                      </a> */}
                  </Col>
                </Row>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            // onRow={(record, index) => ({
            //   style: {
            //     background: record.quotasNaoPagas > 0 ? "#ffdddd" : "#ddffdd",
            //   },
            // })}
            // rowClassName={(record, index) =>
            //   record.quotasNaoPagas > 0 ? "table-danger" : "table-success"
            // }
            dataSource={dataToShow}
            // pagination={{ pageSize: tablePagination }}
          />
          <Divider />
        </ManageVoucher>
      </Drawer>
    </>
  );
}
