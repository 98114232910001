import styled from "styled-components";
import React, { useContext, useMemo, useState, useEffect } from "react";
import Sidebar from "../../../Components/Backoffice/Sidebar";
import Cards from "../../../Components/Backoffice/Card";
import Title from "../../../Components/Backoffice/Title";
import Header from "../../../Components/Backoffice/Header";
import Footer from "../../../Components/Backoffice/Footer";
import {
  HolderOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  UploadOutlined,
  EyeOutlined,
  CheckOutlined,
  LinkOutlined
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Avatar,
  Card,
  List,
  notification,
  Drawer,
  Upload,
  Row,
  Input,
  Col,
  Button,
  Modal,
  Space,
  Table,
} from "antd";
import { ReactComponent as IconUpload } from "../../../Assets/Images/Backoffice/Upload.svg";
import axios, { all } from "axios";
import { ReactComponent as IconInfo } from "../../../Assets/Images/Backoffice/Notifications/Info.svg";
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import { ReactSortable } from "react-sortablejs";
const { Meta } = Card;
import { Link } from "react-router-dom";
const { Dragger } = Upload;

const Wrapper = styled.main`
  background-color: #e8e9f3;
  display: grid;
  grid-template-columns: 7.083vw 1fr;
  min-height: 100vh;


  .ant-upload, .ant-upload-select, .ant-upload-wrapper, .ant-upload-wrapper .ant-upload-select {
    width: 100% !important;
  }

  .btn-descricao {
    margin-top: 10px;
    height: 45px !important;
    padding: 0 15px;
    font-weight: bold;
  }

  .div-guideline-img {
    height: 100px;
    width: 250px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .btn-action {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #newClube {
    background: #e8e9f3 0% 0% no-repeat padding-box;
    border: 4px dashed #d6d7e2;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;

    .cta {
      border-radius: 12px;
      height: 46px;
    }
  }

  .icon-edit {
    cursor: pointer;
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }

  @media (max-width: 992px) {
  }
`;

const Content = styled.div`
  padding: 1.771vw;
`;

const Files = styled.section`
  display: flex;
  flex-direction: column;

  h4 {
    text-align: left;
    font: normal normal bold 20px/20px Roboto;
    letter-spacing: 0px;
    color: #050505;
  }

  .text {
    color: #809fb8;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
  }

  .center {
    text-align: center;
  }

  .cta {
    display: block !important;
    font-size: 15px;
    font-weight: bold;
    height: 45px;
    line-height: 18px;

    i {
      margin: 0 3px;
    }

    a {
      text-decoration: none;
    }
  }

  .cta.publish {
    margin-right: 0;

    svg path {
      fill: #fff;
    }
  }
`;

const CardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  // gap: 60px;
  grid-template-columns: 1fr auto auto;
`;

const Grid = styled.div`
  display: grid;
  gap: 18px;
  margin: 5px 0;

  .item {
    background-color: #d6d7e2;
    border: none;
    border-radius: 16px;
    color: #8688a8;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 12px 0.625vw;
  }

  .space-gestor {
    min-height: 100px !important;
  }

  .item.disable {
    opacity: 0.5;
  }

  .item__image {
    position: relative;
    align-items: center;
    justify-content: center;
    border: 3px solid #0c1151;
    border-radius: 16px;
    max-height: 180px;
    display: flex;
    padding: 0;

    * {
      color: #0c1151;
      font-family: "Fira Sans";
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .item__status {
    background-color: #ea3a3d;
    color: #ffffff;
    padding: 6px 15px;
    border-radius: 8px;
    place-self: flex-start;
  }

  .item__status.active {
    background-color: #1ad598;
  }

  .item__cta {
    margin-right: auto;
  }

  .link {
    text-decoration: none;
  }

  .card-guideline {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23%;
    margin: 1%;
  }

  .div-sortable {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
`;

const AlertMesssage = styled.div`
  background: #f1f5f7;
  border: 2px solid #ffffff;
  margin-top: 10px;
  border-radius: 16px;
  text-align: left;
  letter-spacing: 0px;
  padding: 10px;
  color: #0c1151;
  font-style: italic;

  circle {
    fill: #0c1151;
  }

  svg path {
    fill: #fff;
  }
`;

var styles = {
  h6_label: {
    color: "#809FB8",
    fontFamily: "Roboto",
    fontSize: "14px",
    marginTop: "2%",
    marginBottom: "0%",
    
  },
  input_class: {
    borderRadius: "6px",
    height: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  text_class: {
    borderRadius: "6px",
    minHeight: "45px",
    border: "1px solid #809FB8",
    color: "#0C1151",
    marginBottom: "1%",
  },
  switch_label: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    borderRadius: "8px",
    padding: "0px 16px",
    color: "#809FB8",
    border: "1px solid #809FB8",
    textAlign: "center",
  },
  upload: {
    width: "100%",
  },
};

export default function DashboardProtocolos() {
  document.title = "SPAP - Guidelines";
  const session_storage = JSON.parse(localStorage.getItem("session_data"));
  const [modalCriaGuidelineOpen, setModalCriaGuidelineOpen] = useState(false);
  const [fileGuideline, setfileGuideline] = useState([]);
  const [fileGuidelineHover, setfileGuidelineHover] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [guidelineFiles, setGuidelineFiles] = useState([]);
  const [guidelineFilesToShow, setGuidelineFilesToShow] = useState([]);
  const [imagem, setImagem] = useState([]);
  const [tipo, setTipo] = useState([]);
  const [imagemHover, setImagemHover] = useState([]);
  const [name, setName] = useState([]);
  const [descricao, setDescricao] = useState([]);
  const [descricaoAlt, setDescricaoAlt] = useState([]);
  const [link, setLink] = useState([]);
  const [showGuidelineDrawer, setShowGuidelineDrawer] = useState(false);
  const [showFilesDrawer, setShowFilesDrawer] = useState(false);
  const [guidelineIdEdit, setGuidelineIdEdit] = useState(false);
  const [idToRemove, setIdToRemove] = useState(false);
  const [titleToRemove, setTitleToRemove] = useState(false);
  const [removeGuidelineModalOpen, setRemoveGuidelineModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceFile, setDataSourceFile] = useState([]);
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        console.log(activeIndex);
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );

        let array = arrayMove(prevState, activeIndex, overIndex);
        handleOrdem(array);

        return array;
      });
    }
  };


  const onDragEndFile = ({ active, over }) => {
    if (active.id !== over?.id) {
      setGuidelineFilesToShow((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        console.log(activeIndex);
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );

        let array = arrayMove(prevState, activeIndex, overIndex);
        handleOrdemFile(array);

        return array;
      });
    }
  };

  useEffect(() => {
    guidelines.map((guideline) => {
      guideline.files = guidelineFiles.filter((file) => {return file.guideline_id == guideline.id}).length;
    });
    setGuidelines(guidelines);
    if (guidelineIdEdit) {
      let guidelineFilesByGroup = guidelineFiles.filter((guideline) => { return guideline.guideline_id == guidelineIdEdit});
      guidelineFilesByGroup?.sort((a, b) => a.order - b.order);
      setGuidelineFilesToShow(guidelineFilesByGroup);
      console.log(guidelineFilesByGroup);
    } 
  }, [guidelineFiles]);

  // Função para adicionar um arquivo à lista
  const handleAddFile = (file) => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('guidelineId', guidelineIdEdit);
    bodyFormData.append('file', file);

    fetch("https://api.spap.pt/api/admin/upload-guideline-file", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setGuidelines(data.guidelines);
        setGuidelineFiles(data.files);
        setLoading(false);
        notification.success({
          description:
            "Ficheiro carregado com sucesso!",
        });
      });
    });

    return; // Impede o comportamento padrão de upload
  };

  // Função para remover um arquivo da lista
  const handleRemoveFile = (id) => {
    const bodyFormData = new FormData();
    bodyFormData.append('idToRemove', id);

    fetch("https://api.spap.pt/api/admin/remove-guideline-file", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setGuidelineFiles(data.files);
        setLoading(false);
        notification.success({
          description:
            "Ficheiro removido com sucesso!",
        });
      });
    });
  };

  const handleStatusFile = (id, status) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', id);
    let newStatus = 'active';
    if (status == 'active') {
      newStatus = 'inactive';
    }
    bodyFormData.append('status', newStatus);

    fetch("https://api.spap.pt/api/admin/switch-status-file", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        setGuidelineFiles(data.files);
        setLoading(false);
        notification.success({
          description:
            newStatus == 'active' ? "O ficheiro passou a estar visivel" : "O ficheiro passou a estar escondido"
        });
      });
    });
  };

  useEffect(() => {

    fetch(`https://api.spap.pt/api/all-guidelines`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar guidelines");
        }
        return response.json();
      })
      .then((data) => {
        if (data.guidelines.length === 0) {
          console.log("nenhum guideline");
          return;
        }
        setGuidelines(data.guidelines);
        setGuidelineFiles(data.files);
      })
      .catch((error) => console.error(error));
  }, []);

  const RowContext = React.createContext({});

  useEffect(() => {
    let guidelinesToShow = guidelines.sort((a, b) => a.order - b.order);
    guidelinesToShow?.map((item, index) => {
      item.key = index;
    });
    setDataSource(guidelinesToShow);
  }, [guidelines]);

  notification.config({
    placement: "bottomRight",
    bottom: 10,
    duration: 5,
    rtl: false,
  });

  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <Button
        type="text"
        size="small"
        icon={<HolderOutlined />}
        style={{
          cursor: "move",
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const RowTable = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };
  const columnsfile = [
    {
      key: "sort",
      align: "center",
      width: 20,
      render: () => <DragHandle />,
    },
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "Ações",
      render: (item) => (
        <>
          <Space size={2}>
            <Button
              type="text"
              icon={(item.status == 'active') ? <EyeOutlined /> : <EyeOutlined style={{ opacity: '0.3'}}/>}
              onClick={() => handleStatusFile(item.id, item?.status)}
            />
            <Button
              type="text"
              icon={<LinkOutlined />}
              onClick={() => window.open("http://api.spap.pt/guidelines/" + item.file, "_blank")}
            />
            <Button
              type="text"
              danger
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveFile(item.id)}
            />
          </Space>
        </>
      ),
    },
  ];

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Nome do grupo",
      dataIndex: "name",
    },
    {
      title: "Imagem",
      render: (item) => (
        <>
          <div
            className="div-guideline-img"
            style={{
              backgroundImage:
                "url(https://api.spap.pt/guidelines/" + item.image + ")",
            }}
          ></div>
        </>
      ),
    },
    {
      title: "Número de ficheiros",
      dataIndex: "files",
    },
    {
      title: "Ações",
      render: (item) => (
        <>
          <Space>
            <Button
              className="btn-action"
              onClick={() => clickToGuidelineDrawer(item)}
            >
              <EditOutlined />
            </Button>
            <Button
              className="btn-action"
              onClick={() => clickToFileDrawer(item)}
            >
              <FileOutlined 
              />
            </Button>
            <Button
              className="btn-action"
              onClick={() => openRemoveGuidelineModal(item.id, item.name)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const propGuideline = {
    onRemove: (file) => {
      const index = fileGuideline.indexOf(file);
      const newfileGuidelineList = fileGuideline.slice();
      newfileGuidelineList.splice(index, 1);
      setfileGuideline(newfileGuidelineList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileGuideline([...fileGuideline, file]);
      return false;
    },
    fileGuideline,
  };

  const clickToGuidelineDrawer = (item) => {
    setGuidelineIdEdit(item.id);
    setName(item.name);
    setImagem(item.image);
    setShowGuidelineDrawer(true);
  };


  const clickToFileDrawer = (item) => {
    let guidelineFilesByGroup = guidelineFiles.filter((guideline) => { return guideline.guideline_id == item.id});
    guidelineFilesByGroup?.sort((a, b) => a.order - b.order);
    setGuidelineFilesToShow(guidelineFilesByGroup);
    setGuidelineIdEdit(item.id);
    setName(item.name);
    setShowFilesDrawer(true);
  };

  const propGuidelineHover = {
    onRemove: (file) => {
      const index = fileGuideline.indexOf(file);
      const newfileGuidelineList = fileGuideline.slice();
      newfileGuidelineList.splice(index, 1);
      setfileGuidelineHover(newfileGuidelineList);
    },
    beforeUpload: (file) => {
      const isImage =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml";
      if (!isImage) {
        notification.error({
          description: "Apenas é possível fazer upload de imagens.",
        });

        return isImage || Upload.LIST_IGNORE;
      }
      setfileGuidelineHover([...fileGuidelineHover, file]);
      return false;
    },
    fileGuidelineHover,
  };

  const handleFormGuideline = (e) => {
    e.preventDefault();
    if (!fileGuideline?.length) return;
    var bodyFormData = new FormData();

    bodyFormData.append("name", name);
    bodyFormData.append("image", fileGuideline[0]);

    axios
      .post("https://api.spap.pt/api/admin/store-guideline", bodyFormData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setGuidelines(res.data.guidelines);
          setGuidelineFiles(res.data.files);
          notification.success({
            description: "O guideline foi criado com sucesso",
          });
          setModalCriaGuidelineOpen(false);
          setfileGuideline([]);
          setName("");
        }
      })
      .catch((error) => {
        setModalCriaGuidelineOpen(false);
        notification.error({
          description:
            'Erro ao criar o guideline "' +
            e.target.name.value +
            '". Por favor, tente de novo.',
        });
      });
      
  };

  const propsAddFile = {
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleFormEditGuideline = () => {
    if (!name?.length) return;

    var bodyFormData = new FormData();
    bodyFormData.append("id", guidelineIdEdit);
    bodyFormData.append("name", name);
    bodyFormData.append("image", fileGuideline[0]);

    axios
      .post("https://api.spap.pt/api/admin/update-guideline", bodyFormData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + session_storage.token,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setGuidelines(res.data.guidelines);
          setGuidelineFiles(res.data.files);
          setShowGuidelineDrawer(false);
          setfileGuideline([]);
          setName("");
          notification.success({
            description: "O guideline foi atualizado com sucesso.",
          });
        }
      })
      .catch((error) => {
        notification.error({
          description: "Erro ao criar o guideline. Por favor, tente de novo.",
        });
      });
  };

  const getGuidelinesOrder = () => {
    if (thisGuidelines?.length > 0) {
      guidelines.sort((a, b) => a.ordem - b.ordem);
    }
    return thisGuidelines;
  };

  const handleOrdem = (array, tipo) => {
    let ordemGuidelines = [];
    array.map(function (item, index) {
      ordemGuidelines.push({ ordem: index, id: item.id });
    });

    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemGuidelines));

    fetch("https://api.spap.pt/api/admin/set-ordem-guideline", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        notification.success({
          description: "A ordem dos guidelines foi atualizada com sucesso!",
        });
        setGuidelines(data.guidelines);
        setGuidelineFiles(data.files);
      });
    });
  };

  const handleOrdemFile = (array, tipo) => {
    let ordemGuidelines = [];
    array.map(function (item, index) {
      ordemGuidelines.push({ ordem: index, id: item.id });
    });

    var bodyFormData = new FormData();

    bodyFormData.append("array", JSON.stringify(ordemGuidelines));

    fetch("https://api.spap.pt/api/admin/set-ordem-guideline-file", {
      method: "POST",
      body: bodyFormData,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_storage.token,
      },
    }).then((response) => {
      response.json().then((data) => {
        notification.success({
          description: "A ordem dos ficheiros foi atualizada com sucesso!",
        });
        setGuidelines(data.guidelines);
        setGuidelineFiles(data.files);
      });
    });
  };


  const openRemoveGuidelineModal = (id, name) => {
    setIdToRemove(id);
    setTitleToRemove(name);
    setRemoveGuidelineModalOpen(true);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleViewFile = (file) => {
    setSelectedFile(file);
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
  };

  const handleRemoveGuideline = () => {
    axios
      .post(
        "https://api.spap.pt/api/admin/remove-guideline",
        { idToRemove },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + session_storage.token,
          },
        }
      )
      .then((res) => {
        setIdToRemove("");
        setRemoveGuidelineModalOpen(false);
        if (res.status == 200) {
          setGuidelines(res.data.guidelines);
          setGuidelineFiles(res.data.files);
          notification.success({
            description: "O guideline foi removido com sucesso!",
          });
        }
      })
      .catch((error) => {
        // notification.error({description: 'Erro ao remover a imagem da galeria! Tente novamente.'});
      });
  };


  return (
    <Wrapper>
      <div>
        <Sidebar />
      </div>
      <Content>
        <Header />

        <Cards>
          <CardHeader>
            <Title>Guidelines</Title>

            <Button
              onClick={setModalCriaGuidelineOpen}
              style={{
                background: "#494D7D",
                color: "#fff",
                borderRadius: "6px",
                height: "42px",
                marginRight: "inherit",
              }}
            >
              Criar novo grupo
            </Button>
          </CardHeader>
          <AlertMesssage>
            <Space size={8}>
              <IconInfo className="icon icon-info" />
              <span>
                Para poder alterar a ordem dos guideline basta fazer "drag and
                drop" nas listagens respetivas.
              </span>
            </Space>
          </AlertMesssage>
          <Grid>
            {dataSource?.length == 0 && (
              <span style={{ textAlign: "center" }}>
                Não existem guidelines disponíveis.
              </span>
            )}
            {dataSource?.length > 0 && (
              <>
                <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    items={dataSource.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      rowKey="id"
                      components={{
                        body: {
                          row: RowTable,
                        },
                      }}
                      columns={columns}
                      dataSource={dataSource}
                    />
                  </SortableContext>
                </DndContext>
              </>
            )}
          </Grid>
        </Cards>
        <Footer />
      </Content>
      <Modal
        className="modal-anticorpos"
        open={modalCriaGuidelineOpen}
        title="Adicionar novo grupo"
        onCancel={() => setModalCriaGuidelineOpen(false)}
      >
        <form id="create-guideline" onSubmit={handleFormGuideline}>
          <Row gutter={[8, 8]}>
            <Col
              span={24}
              style={{
                height: "-webkit-fill-available",
              }}
            >
              <h6 style={styles.h6_label}>Imagem Principal</h6>
              <Dragger
                key={"imagem-guideline"}
                {...propGuideline}
                fileList={[...fileGuideline]}
                accept="image/*"
              >
                <div>
                  <p className="ant-upload-drag-icon">
                    <IconUpload />
                  </p>
                  <p className="ant-upload-text text">
                    Upload da imagem de capa
                  </p>
                  <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                </div>
              </Dragger>
            </Col>
          </Row>
          <h6 style={styles.h6_label} className="mt-10">Título</h6>
          <Input
            value={name}
            key={"name-guideline"}
            onChange={(e) => setName(e.target.value)}
            type="text"
            name="name"
            style={styles.input_class}
            placeholder="Título"
          />
          <Input
            type="submit"
            value="Guardar"
            style={{
              marginInlineStart: "0",
              background: "#494D7D",
              color: "#fff",
              borderRadius: "6px",
              width: "100%",
              height: "45px",
              marginTop: "2%",
            }}
          />
        </form>

        <Button
          onClick={() => setModalCriaGuidelineOpen(false)}
          style={{
            marginInlineStart: "0",
            background: "#fff",
            color: "#494D7D",
            border: "1px solid #494D7D",
            borderRadius: "6px",
            width: "100%",
            height: "45px",
            marginTop: "2%",
          }}
        >
          Voltar
        </Button>
      </Modal>

      <Drawer
        key={"edit-guideline-" + guidelineIdEdit}
        title="Editar grupo"
        width={500}
        onClose={() => setShowGuidelineDrawer(false)}
        open={showGuidelineDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={() => setShowGuidelineDrawer(false)}>Cancelar</Button>
            <Button
              onClick={handleFormEditGuideline}
              type="submit"
              style={{
                borderRadius: "6px",
                background: "#494D7D",
                color: "#fff",
              }}
            >
              Guardar
            </Button>
          </Space>
        }
      >
        <form layout="vertical" id="update-event-form">
          <Col span={24}>
            <Row gutter={[8, 8]} style={{ width: "100%" }}>
              <Col
                span={24}
                style={{
                  height: "-webkit-fill-available",
                }}
              >
                <h6 style={styles.h6_label}>Imagem Principal</h6>
                <Dragger
                  key={"imagem-guideline-edit-" + guidelineIdEdit}
                  {...propGuideline}
                  fileList={[...fileGuideline]}
                  style={{
                    height: "150px",
                    backgroundImage:
                      "url(https://api.spap.pt/guidelines/" + imagem + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                  accept="image/*"
                >
                  <div>
                    <p className="ant-upload-drag-icon">
                      <IconUpload />
                    </p>
                    <p
                      className="ant-upload-text text"
                      style={{ fontSize: "14px" }}
                    >
                      Upload da imagem da capa
                    </p>
                    <p className="ant-upload-hint">Faça Drag and drop aqui</p>
                  </div>
                </Dragger>
              </Col>
            </Row>
            <h6 style={styles.h6_label}>Título</h6>
            <Input
              value={name}
              key={"name-guideline-edit-" + guidelineIdEdit}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
              style={styles.input_class}
              placeholder="Título"
            />
          </Col>
        </form>
      </Drawer>

      <Drawer
        key={"edit-files-" + guidelineIdEdit}
        title={"Ficheiros relativos ao grupo"}
        width={500}
        onClose={() => {
          setGuidelineIdEdit('');
          setShowFilesDrawer(false)}}
        open={showFilesDrawer}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={() => setShowFilesDrawer(false)}>Cancelar</Button>
          </Space>
        }
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Col span={24} className="bg-light-blue">
              <h5 className="mb-0">{name}</h5>
            </Col>
            <Col span={24}>
              <Upload
                className="w-100" 
                // {...propsAddFile}
                customRequest={({ file }) => handleAddFile(file)} // Funcionalidade personalizada para adicionar arquivos
                showUploadList={false}
                style={{ width: '100%'}}
                accept=".pdf, .doc, .docx, .ppt, .pptx, .xlsx"
              >
                <Button icon={<UploadOutlined />} loading={loading} className="w-100 h-45 mb-10">
                  Adicionar ficheiro
                </Button>
              </Upload>
            </Col>
          </Row>
          {/* Lista de arquivos adicionados */}
          <DndContext
                  modifiers={[restrictToVerticalAxis]}
                  onDragEnd={onDragEndFile}
                >
                  <SortableContext
                    items={guidelineFilesToShow.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      rowKey="id"
                      components={{
                        body: {
                          row: RowTable,
                        },
                      }}
                      columns={columnsfile}
        pagination={false} 
                      dataSource={guidelineFilesToShow}
                    />
                  </SortableContext>
                </DndContext>
        </Space>
      </Drawer>
      <Modal
        key={"remove-guideline-" + idToRemove}
        title="Está prestes a eliminar um guideline"
        open={removeGuidelineModalOpen}
        onOk={handleRemoveGuideline}
        onCancel={() => setRemoveGuidelineModalOpen(false)}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <p>
          Tem a certeza que pretende remover o guideline <b>'{titleToRemove}'</b>?
        </p>
      </Modal>
    </Wrapper>
  );
}
