import styled from 'styled-components'
import { Link } from 'react-router-dom';
import AuthService from "../../../Services/auth.service";
import { useNavigate } from 'react-router-dom';
import  {useState } from 'react'
import { useForm } from "react-hook-form";
import { Input, notification } from 'antd';
import perfilcss from '../../../../src/Assets/css/backoffice.css'
import { EyeInvisibleOutlined, EyeTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';

const Wrapper = styled.main`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px ;

  // min-height: calc(100vh - 3.12vw);

`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: #809FB8;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }

  p.left {
    text-align: left;
    align-items: center;
    display: inline-flex;
  }

  p>* {
    color: #809FB8;
    margin-left: 5px;
  }

  label {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #0C1151;
  }

  .bold {
    font-weight: 900;
  }

  input[type="email"],
  input[type="password"] {
    background-color: #D9E1E700;
    border: 1px solid rgb(128, 159, 184);
    border-radius: 6px;
    padding: 10px 10px;
    width: 100%;
    outline: 0;
    transition: .3s ease;
    text-align: center;
  }

  label {
    margin-top: 1%;
  }

  input[type="password"] {
    margin-bottom: 0px;
  }

  input[type="email"]:focus,
  input[type="password"]:focus {
    box-shadow: 0 8px 24px #00000025;
  }

  input[type="checkbox"] {
    border: 2px solid #E0E7EB;
    border-radius: 0.417vw;
    cursor: pointer;
    height: 20px;
    margin-right: 11px;
    width: 20px;
  }

  input[type="submit"] {
    background-color: ${props => props.theme.blueLight};
    border: none;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: 0px;
    color: ${props => props.theme.white};
    opacity: 1;
    height: 45px;
    padding: 0px 60px;
    place-self: center;
    transition: .3s ease;
    margin-top: 10px;
    width: 100%;
  }

  input[type="submit"]:hover {
    background-color: ${props => props.theme.blue};
    color: ${props => props.theme.whiteHover};
  }

  .link-checkbox,
  .link-password {
    transition: .3s ease;
  }

  .link-password {
    color: rgb(73, 77, 125);
  }

  .link-password:hover {
    color: #809FB8;
  }
  
  .link-checkbox:hover {
    color: rgb(73, 77, 125);
  }

  @media (max-width: 992px) {
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      border-radius: 6px;
      font-size: 16px;
      line-height: 20px;
      padding: 10px;
    }

    input[type="checkbox"] {
      height: 15px;
      width: 15px;
    }

    input[type="submit"] {
      border-radius: 6px;
      font-size: 16px;
      line-height: 22px;
      width: 100%;
    }
    
  }

    span[role=alert] {
    color:red;
  }
`

export default function LoginForm( props ) {
  document.title = 'SPAP - Login'

  const navigate = useNavigate();
  const [hasError,setHasError] = useState(false);
  const [errorMessage,setErrorMessage] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  notification.config({
    placement: 'topRight',
    top: 20,
    duration: 5,
    rtl: false
  });

  const handleSubmit = (e) => {

    e.preventDefault();

    let email = e.target.email.value;
    let password = e.target.password.value;

    AuthService.login(email, password).then(() => {
      const session_storage = JSON.parse(localStorage.getItem('session_data'));

      if (localStorage.getItem('cartList') != null) {
        localStorage.removeItem('cartList');
      }

      if(session_storage.all_data.role == 'basic') {
        navigate("/");      
      } else {
        navigate("/dashboard");      
      }
    }).catch( (request) => {
      setHasError(true)
      setErrorMessage("O email e a password inseridos não coincidem.");
      setTimeout(() => {
        setHasError(false);
      },3000)
    })
  }

  return(
    <Wrapper>
      <Form id="login-form" onSubmit={(e) => handleSubmit(e)}>
        <p>Coloque abaixo o seu email e password</p>
        <label>
          Email *
          <input type="email" name="email" placeholder="Email" required />
        </label>
        <label>
          Password *
          <Input.Password
            name="password"
            placeholder="input password"
            iconRender={visible => (visible ? <EyeTwoTone twoToneColor="rgb(73, 77, 125)"/> : <EyeInvisibleOutlined />)}
          />
        </label>
        {hasError && 
          <div style={{ padding: '10px', background: '#ffc3c3', border: '1px solid #ffc3c3', borderRadius: '10px', display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined twoToneColor="red"/> <span style={{ paddingLeft: '10px', fontSize: '14px'}}>{errorMessage}</span></div>
        }
        <input type="submit" name="submit" value="Entrar"/>
        <p>Desejo recuperar a minha<u><Link to={'/recuperar-senha'} className='link-password'>password</Link></u>.</p>
      </Form>
    </Wrapper>
  )
}
