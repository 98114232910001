import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../../../Components/Container";
import FlexInRow from "../../../Components/FlexInRow";
import FlexInColumn from "../../../Components/FlexInColumn";
import PageTitle from "../../../Components/PageTitle";
import PageText from "../../../Components/PageText";
import RecommendedLinks from "../../../Components/RecommendedLinks";
import { Helmet } from "react-helmet";
import { ReactComponent as ReactLogo } from "../../../Assets/Images/icon-awesome-file-alt.svg";
import { Button, Col, Divider, Empty, Row, Space } from "antd";
import { useEffect } from "react";
import Meta from "antd/es/card/Meta";
import {
  HolderOutlined,
  DeleteOutlined,
  EditOutlined,
  FileWordOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  FilePdfOutlined,
  LinkOutlined
} from "@ant-design/icons";
const Wrapper = styled.main`
  background-color: #ffffff;
  color: #ffffff;
  font-size: 1.042vw;
  letter-spacing: 0px;
  line-height: inherit;
  text-align: center;
  width: 100%;
  margin-top: 7.813vw;

  .btn-download-document {
    height: max-content !important;
    padding: 15px;
  }

  > .content {
    margin-bottom: 2.188vw;
  }

  .fl-right {
    float: right !important;
  }

  .t-wrap {
    text-wrap: auto !important;
  }

  .card-details {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .bookname {
    margin-top: 10px !important;
    font-size: 23px;
    color: #000;
  }

  .bookdetails {
    margin-top: 10px !important;
    margin-left: 20px;
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
  }

  .search-input .ant-input-wrapper {
    
  }

  .c-pointer {
    cursor: pointer !important;
  }

  .edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-slider button {
    color:rgb(46,112,146) !important;
  }
 
  .slick-dots:not(.slick-active) button {
    background: #000 !important;
  }
  .slick-dots .slick-active button {
    background: rgb(102, 153, 180) !important;
  }

  .span-switch {
    color: #000;
  }

  .card-guide .ant-space-item {
    width: 100%;
  }

  .carousel .slick-list, .slick-track {
    margin: 0 !important;
    
  }

  .ant-divider {
    margin: 8px 0;
  }

  form .ant-card-head {
    background: #2e70921c;
  }

  .ant-card-cover  {
    height: 200px !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 15px 15px 0px 0px !important;
  }

  .card-guide {
    border-radius: 14px  !important;
    border: 1px solid rgb(134, 136, 168) !important;
  }

  .ant-card-cover {
    border-bottom: 1px solid #cccccc5c;
  }

  .ant-form-item { 
    margin-bottom: 5px;
  }

  .ant-form-item-label {
    padding-bottom: 2px;
  }

  .ant-select {
    height: 40px !important;
  }

  .pt-10 .ant-card-body {
    padding-top: 10px !important;
    padding-bottom: 5px !important;
  }

  .absolute-tag {
    position: absolute;
    right: 5px;
    top: 10px;
    z-index: 999 !important;
  }

  .absolute-tag .ant-tag {
    float:right !important;
  }

  .ant-upload {
    width: 100% !important;
  }

  .select-type .ant-select-selector {
    height: 40px !important;
    max-height: 40px !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .card-title {
    color: rgb(102, 153, 180) !important;
  }

  .b-blue {
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .ant-switch:not(.ant-switch-checked) .ant-switch-inner {
    background: #ffffff8f !important;
  }

  .ant-switch:not(.ant-switch-checked) .ant-switch-handle:before {
    background: #ccc !important;
  }

  .ant-switch-checked .ant-switch-inner {
    background: #a9afb29c !important;
  }
  
  .ant-switch-checked .ant-switch-handle:before {
    background: rgb(102, 153, 180) !important;
  }

  textarea {
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .text-card {
    text-align: left !important;
    padding: 10px 0;
  }

  .text-card h6 {
    font-size: 17px;
    color: rgb(46,112,146);
  }
  .text-card p {
    font-size: 15px;
  }

  .new-btn {
    background: rgb(102, 153, 180);
    border: 1px solid rgb(102, 153, 180) !important;
    color: #fff;
    padding: 0 20px;
  }

  .h-40, .search-input input, .search-input .ant-btn {
    height: 40px !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .see-more-btn {
    background: rgb(102, 153, 180) !important;
    color: #fff !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .see-more-btn:hover {
    color: rgb(102, 153, 180) !important;
    background: #fff !important;
    border: 1px solid rgb(102, 153, 180) !important;
  }

  .new-btn:hover {
    color: rgb(102, 153, 180) !important;
    background: #fff;
  }

  .ant-tabs-tab {
    padding: 12px 20px;
  }

  .cancel {
    border: 1px solid #ccc !important;
    color: #000 !important;
  }

  .sidebar__image {
    margin-left: auto;
    max-width: 27.917vw;
  }

  img {
    width: 100%;
  }

  a {
    color: #000000;
    place-self: flex-start;
    text-align: left;
    text-decoration: none;
    transition: 0.3s ease;

    :hover {
      color: ${(props) => props.theme.blueHover};
    }
  }

  @media (max-width: 992px) {

    .bookdetails {
      margin-left: 5px !important;
    }

    form .ant-card-head {
      padding-bottom: 10px !important;
    }
    margin-top: 120px;

    a {
      font-size: 3.333vw;
      line-height: 4.444vw;
      place-self: center;
    }

    .sidebar__image {
      margin: auto;
      max-width: 80%;
    }
  }
`;

const SectionCategories = styled.section`
  padding: 2.292vw 0 0;
`;

const CategoriesNav = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2.083vw;

  .item {
    align-items: center;
    background-color: #c2c4d4;
    border-radius: 1.042vw 1.042vw 0 0;
    color: #8688a8;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: 0px;
    transition: 0.3s ease;
    cursor: pointer;
    padding: 5px;

    picture {
      width: 130px;

      img {
        width: 100%;
      }
    }
  }

  .item:hover {
    box-shadow: 0px -8px 20px #00000025;
  }

  .item-${(props) => props.itemOffset} {
    background-color: #d6d7e2;
    color: #494d7d;
  }

  @media (max-width: 992px) {
    .item {
      font-size: 14px;
      line-height: 18px;
    }

    .item picture {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    gap: 48px;

    .item picture {
      gap: 0;
    }
  }
`;

const CategoriesContent = styled.div`
  background-color: #d6d7e2;
  padding: 3.125vw 0;

  .item {
    display: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease;
  }

  .item-${(props) => props.itemOffset} {
    display: flex;
    opacity: 1;
    pointer-events: auto;
  }

  .item-1 picture {
    max-width: 30.417vw;
  }

  @media (max-width: 992px) {
    .item-1 picture {
      max-width: 80%;
    }

    .item-2 picture {
      max-width: 60%;
    }
  }

  @media (min-width: 1920px) {
    .grid-cards {
      gap: 48px;
    }
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border: 0.156vw solid #8688a8;
  border-top: 0;
  border-radius: 0 0 1.042vw 1.042vw;
  display: flex;
  flex-direction: column;
  width: 100%;

  > .title {
    background-color: #494d7d;
    color: #ffffff;
    font-size: 1.146vw;
    font-weight: bold;
    line-height: 1.51vw;
    padding: 0.677vw 1.042vw;
    margin: 0 -0.078vw;
  }

  > .content {
    color: #494d7d;
    padding: 1.302vw;
  }

  > .content p {
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
  }

  > .content p a {
    color: #f88436;
    text-decoration: none;
    justify-content: flex-start;
    transition: 0.3s ease-out;
  }

  > .content p a:hover {
    text-decoration: underline;
  }

  > .bar {
    background-color: #494d7d;
    height: 0.521vw;
  }

  ${(props) =>
    props.backgroundImage &&
    `
    background-image: url(${props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  li,
  li a {
    color: #494d7d;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 24px;
    text-align: left;
    text-decoration: none;
    justify-content: flex-start;
    transition: 0.3s ease-out;
  }

  li a:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: 10px;
  }

  .icon-file {
    height: auto;
    min-width: 18px;
    max-width: 18px;
  }

  .icon-file path {
    fill: #494d7d;
  }

  @media (max-width: 992px) {
    > .title {
      font-size: 18px;
      line-height: 22px;
    }

    .m-0-mobile {
      margin: 0 !important;
    }

    li,
    li a,
    > .content p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    .icon-file {
      min-width: 12px;
      max-width: 12px;
    }
  }
`;

export default function Guidelines(props) {
  const [itemOffset, setItemOffset] = useState(0);
  const [showGroupFiles, setShowGroupFiles] = useState(false);
  const [groupToShow, setGroupToShow] = useState([]);
  const [groupsToShow, setGroupsToShow] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.spap.pt/api/all-guidelines`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Erro ao buscar guidelines");
        }
        return response.json();
      })
      .then((data) => {
        if (data.guidelines.length === 0) {
          console.log("nenhum guideline");
          return;
        }
        handleGroups(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleGroups = (data) => {
    let guidelines = data.guidelines;
    let files = data.files;

    guidelines.map((guideline) => {
      guideline.files = files.filter((file) => { return file.guideline_id == guideline.id && file.status == 'active' });
      guideline.files.sort((a,b) => { return Number(a.order) - Number(b.order)});
    });

    let guidelinesWithFiles = guidelines.filter((guideline) => { return guideline.files.length > 0 })

    setGroupsToShow(guidelinesWithFiles);
  };

  const showFiles = (group) => {
    setGroupToShow(group);
    setShowGroupFiles(true);
  }

  return (
    <Wrapper>
      <Helmet>
        <title>
          Protocolos de Relatórios Sinópticos | Sociedade Portuguesa de Anatomia Patológica - SPAP
        </title>
        <meta
          name="description"
          content="Anatomia Patológica, Nacionais | Anatomia Patológica, Internacionais | Clínicas, Nacionais"
        />
        <meta
          name="keywords"
          content="spap, Sociedade Portuguesa de Anatomia Patológica, Anatomia Patológica, nispap, Núcleo de internos SPAP Sociedade Portuguesa de Anatomia Patológica, sócios spap"
        />
      </Helmet>
      <Container className="content">
        <PageTitle
          title="Protocolos de Relatórios Sinópticos"
          path={[
            {
              text: "Homepage",
              link: "/",
            },
            {
              text: "Ferramentas úteis",
              link: "/ferramentas-uteis",
            },
            {
              text: "Protocolos de Relatórios Sinópticos",
            },
          ]}
          imageDesktop={require("../../../Assets/Images/img-top-guidelines@2x.png")}
          imageMobile={require("../../../Assets/Images/img-top-guidelines.png")}
          imageAlt="Protocolos de Relatórios Sinópticos"
        />

        <p className="black fs-18 mb-25 mt-10 align-center-mobile">Os Protocolos de Relatórios Sinópticos fornecem diretrizes para a documentação completa, estruturada e atualizada dos elementos necessários nos relatórios de patologia tumoral maligna. São essenciais para uma estandardização dos relatórios entre os patologistas nacionais e definem a qualidade pretendida para otimizar a decisão terapêutica e determinação prognóstica.</p>

        <Divider
          variant="dotted"
          style={{
            borderColor: 'rgb(73, 77, 125)',
            fontSize: '16px'
          }}
        >
          Grupos / Guidelines
        </Divider>
        { showGroupFiles && 
        
          <Row gutter={[8,16]} className="card-details mt-25 mb-50-mobile" align={'top'}>
            
            {/* <Col xl={7} xs={24}>
            
                  { groupToShow.image &&
                      <img  alt={groupToShow.name} src={"https://api.spap.pt/guidelines/" + groupToShow.image} />}
            </Col> */}
            <Col xl={24} xs={24} className="m-0-mobile">
            <Row align={'middle'} className="file-group">
              <Col align="left" xl={12} xs={24}>
                <h5 className="blue mb-0">Ficheiros do grupo {groupToShow.name}</h5>
              </Col>
              <Col align="right" xl={12} xs={24} className="mb-10 mt-10-mobile ">
                <Button
                  className="new-btn h-40 fl-right"
                  onClick={() => setShowGroupFiles(false)}
                >
                  <Space>
                    {<ArrowLeftOutlined />}
                    Voltar à listagem
                  </Space>
                </Button></Col>
            </Row>

                  <Space align="start" size={20} className="w-100 mt-25 file-group" wrap>
              { groupToShow.files.sort((a, b) => (a.order - b.order)).map((file) => {
                return (
                  <>
                  <Space type="flex" direction="vertical">
                    <Button className="btn-download-document" >
                      { file.name.split('.').pop() == 'docx' && 
                      <>
                      <a className="blue c-pointer" href={'https://api.spap.pt/guidelines/' + file.name} download><FileWordOutlined style={{ fontSize: '25px' }}/></a></>}
                      { file.name.split('.').pop() == 'pdf' && 
                      <>
                      <a className="blue c-pointer" href={'https://api.spap.pt/guidelines/' + file.name} download><FilePdfOutlined style={{ fontSize: '25px' }}/></a></>}
                    </Button>
                    <a className="blue c-pointer" href={'https://api.spap.pt/guidelines/' + file.name} download>{file.name}</a>
                    </Space>
                  </>
                )
              })}
              </Space>
            </Col>
          </Row>
        }
        {groupsToShow.length == 0 && !showGroupFiles && (
          <Card className="mt-10">
            <Empty className="empty-box" description="Não foram encontrados grupos" />
            </Card>
        )}
        <div className="mt-20 mb-50-mobile">
          <Row gutter={[16, 16]}>
            {!showGroupFiles && groupsToShow.map(group => (
              <Col key={group.id} xs={24} sm={12} md={8}>
                <Card
                  className="card-guide"
                  // hoverable
                  cover={<img alt={group.name} src={"https://api.spap.pt/guidelines/" + group.image} />}
                >
                  <div className="ant-card-cover" style={{ backgroundImage: "url(https://api.spap.pt/guidelines/" + group.image + ")"}}>
                  
                  </div>
                  <Row align={'center'} className="p-10" >
                    <Col span={24} align="center" className="mb-10">
                      <h5 className="card-title">{group.name}</h5>
                    </Col>
                    <Col span={24} align="center">
                    <Space className="w-100">
                      <Button className="see-more-btn edit-btn w-100" onClick={() => showFiles(group)}><EyeOutlined /> Ver { group.files.length} ficheiros</Button>
                    </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>


      <RecommendedLinks
        title="PODERÁ TER INTERESSE EM VISITAR AS PÁGINAS:"
        links={[
          {
            title: "História",
            link: "/spap/historia",
          },
          {
            title: "Órgãos sociais",
            link: "/spap/orgaos-sociais",
          },
          {
            title: "Bolsas e prémios",
            link: "/spap/bolsas-e-premios",
          },
          {
            title: "Vantagens de ser sócio",
            link: "/socios/conheca-as-vantagens",
          },
        ]}
      />
    </Wrapper>
  );
}
